import { ExpandablePageActionButton, ModularPage } from '@paddl/storybook';
import {
  getActivityProgressTypeByVariantId,
  getActivityTitleByVariantId,
  getDecodedUserSessionCookie,
  hardRedirect,
  isToggleEnabled,
  trackEvent,
  urlStringForPathKey
} from '@paddl/utils-js';
import {
  MemberAppNavigation_Populated,
  MemberAppNavigation_Populated_NEON,
  withDataFetching,
  withDataSubmission
} from '@paddl/utils-react';
import { func, shape, string } from 'prop-types';
import React, {
  useEffect,
  useState
} from 'react';
import { compose } from 'recompose';
import { DeleteActivity } from '../../components';
import { ViewActivitySideContent } from './ViewActivtySideContent';
import { ViewOthersActivity } from './ViewOthersActivity';
import { ViewOwnActivity } from './ViewOwnActivity';

export const ActivityComp = ({
  match: {
    params: {
      activityId
    }
  },
  activity: {
    fetch,
    response: activityResponse,
    isSuccess,
    response: {
      metadata,
      variantId,
      xpNumber
    }
  },
  setProfileViews: {
    submit: setNewProfileView
  },
  deleteActivityMedia: {
    fetch: fetchDeleteMedia
  }
}) => {
  const decodedCookie = getDecodedUserSessionCookie();

  const profileId = (decodedCookie && decodedCookie.profile_id) || '';
  const isViewingOwnActivity = metadata?.authorId?.toString() === profileId;
  const activityTitle = variantId && getActivityTitleByVariantId(variantId);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  useEffect(() => {
    if (activityResponse.errorMessage) {
      console.error(activityResponse.errorMessage);

      window.location.href = '/not-found';
    }
  }, [activityResponse]);

  useEffect(() => {
    fetch(`${process.env.API_PREFIX_V2}/activities/${activityId}`);
  }, [activityId, fetch]);

  useEffect(() => {
    if (metadata?.authorId) {
      const { authorId } = metadata;

      setNewProfileView(`${process.env.PROFILE_ANALYTICS_API_V1}/view/${authorId}`, '*');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata]);

  if (!isSuccess) return null;

  const progressType = getActivityProgressTypeByVariantId(variantId);
  const activity = {
    ...activityResponse,
    ...(progressType === 'TIME_STAMP'
      ? {
        duration: {
          ...activityResponse.duration,
          timeStamp: activityResponse.duration.startDate
        }
      }
      : {})
  };

  const pageContent = isViewingOwnActivity ? <ViewOwnActivity
    activity={activity}
    isShareModalOpen={isShareModalOpen}
    setIsShareModalOpen={setIsShareModalOpen}
  /> : <ViewOthersActivity activity={activityResponse} />;
  const sidebarContent = <ViewActivitySideContent duration={activityResponse.duration} activityTitle={activityTitle} xpNumber={xpNumber} metadata={metadata} />;

  const deleteAndRedirect = async () => {
    try {
      await Promise.all([
        fetchDeleteMedia(`${process.env.ACTIVITY_MEDIA_API_V1}/activity/${activityId}`, 'delete')
      ]);

      trackEvent('Removed an Activity', { activityId });
      window.location.href = urlStringForPathKey('profileById', { id: profileId });
    } catch (error) {
      console.error(error);
    }
  };
  const pageActionItems = isViewingOwnActivity ? [
    <DeleteActivity action={() => deleteAndRedirect()} />,
    <ExpandablePageActionButton onClick={() => setIsShareModalOpen(true)} expandedText="Share" icon="share" variant="secondary" />
  ] : [];
  const breadcrumbItems = [
    {
      title: 'Activities',
      action: () => hardRedirect('profileById', { id: metadata?.authorId })
    },
    { title: activityTitle }
  ];

  return <ModularPage
    pageContent={pageContent}
    sidebarContent={sidebarContent}
    pageName="Activity"
    navigationContent={isToggleEnabled('neon')
      ? <MemberAppNavigation_Populated_NEON currentPage="activity" />
      : <MemberAppNavigation_Populated currentPage="activity" />}
    breadcrumbItems={breadcrumbItems}
    pageActionItems={pageActionItems}
  />;
};

ActivityComp.propTypes = {
  match: shape({
    params: shape({
      activityId: string.isRequired
    }).isRequired
  }).isRequired,
  activity: shape({
    fetch: func.isrequired,
    response: shape({}).isRequired
  }).isRequired,
  setProfileViews: shape({
    submit: func.isRequired
  }).isRequired,
  deleteActivityMedia: shape({
    fetch: func.isrequired
  }).isRequired
};

export const Activity = compose(
  withDataFetching({ propNameWrapper: 'activity' }),
  withDataSubmission({ propNameWrapper: 'setProfileViews' }),
  withDataFetching({ propNameWrapper: 'deleteActivityMedia' })
)(ActivityComp);
