import React, { useEffect } from 'react';
import { shape, func } from 'prop-types';
import {
  ActivityCategoryButton, Colors,
  PageHeader,
  InnerContentWrapper, PageColumn, Section, MainContentWrapper,
  Fonts,
  ModularPage
} from '@paddl/storybook';
import Grid from '@mui/material/Grid';
import { activityCategories, isToggleEnabled } from '@paddl/utils-js';
import { Metadata, MemberAppNavigation_Populated, MemberAppNavigation_Populated_NEON } from '@paddl/utils-react';
import styled from '@emotion/styled';
import { ActivityChipList } from '../../../components';

const GridSectionHeader = styled.h2`
    ${Fonts.H4}
        padding-bottom: 16px;
        color: ${Colors.new_grey_light};
        text-align: center;
`;

export const _NewActivity = ({ history }) => {
  const goToCategory = (cat) => history.push(`new/${cat}`);

  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <MainContentWrapper colorVariant="dark">
      <Metadata title="Create Activity | Paddl" />
      <InnerContentWrapper size="small">
        <PageColumn>
          <PageHeader heading="Create a new Activity" tagline="Capture your experience to unlock badges and earn XP." />
        <Section>
        <GridSectionHeader>Suggestions</GridSectionHeader>
        <ActivityChipList history={history} />
        </Section>
        <Section>
        <GridSectionHeader>Choose by Category</GridSectionHeader>
        <Grid
          container
          spacing={2}
        >
          {activityCategories().map(({ title, id, activities }) =>
            <Grid
              item
              xs={6}
              md={4}
              key={id}
            >
              <ActivityCategoryButton
                type={title}
                onClick={() => goToCategory(id)}
                isDisabled={!activities.length}
              />
            </Grid>)}
        </Grid>
        </Section>
        </PageColumn>
      </InnerContentWrapper>
    </MainContentWrapper>
  );
};

_NewActivity.propTypes = {
  history: shape({
    push: func.isRequired
  }).isRequired
};

export const NewActivity = ({ history }) => <ModularPage
  pageContent={<_NewActivity history={history} />}
  navigationContent={isToggleEnabled('neon')
    ? <MemberAppNavigation_Populated_NEON currentPage="activity" />
    : <MemberAppNavigation_Populated currentPage="activity" />}
/>;

NewActivity.propTypes = _NewActivity.propTypes;
